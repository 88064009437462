.mega-menu {
  text-align: left;
  width: 100%;
  display: none;
  position: absolute;
  left: 0;
}

.hoverable {
  position: static;
}

.hoverable > a:after {
  content: "▼";
  padding-left: 6px;
  font-size: 10px;
  position: relative;
  top: -1px;
}

.hoverable:hover .mega-menu {
  display: block;
}

.menuActionDisabled:after {
  content: "▼";
  padding-left: 6px;
  font-size: 10px;
  position: relative;
  top: -1px;
}

.mega-menu {
  display: none;
}

.menuActionEnabled ~ .mega-menu {
  display: block;
}

.menuActionEnabled:after {
  content: "▲";
  padding-left: 6px;
  font-size: 10px;
  position: relative;
  top: -1px;
}

/*# sourceMappingURL=index-fr.131c7a80.css.map */
