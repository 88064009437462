
  /* #Mega Menu Styles
  
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .mega-menu {
    display: none;
    left: 0;
    position: absolute;
    text-align: left;
    width: 100%;
  }



  /* #hoverable Class Styles */
  .hoverable {
    position: static;
  }

  .hoverable > a:after {
    content: "\25BC";
    font-size: 10px;
    padding-left: 6px;
    position: relative;
    top: -1px;
  }

  .hoverable:hover .mega-menu {
    display: block;
  }


  /* #toggle Class Styles */

  .menuActionDisabled:after {
    content: "\25BC";
    font-size: 10px;
    padding-left: 6px;
    position: relative;
    top: -1px;
  }

  
   .mega-menu {
    display: none;
  }

  .menuActionEnabled ~ .mega-menu {
    display: block;
  }

  .menuActionEnabled:after {
    content: "\25B2";
    font-size: 10px;
    padding-left: 6px;
    position: relative;
    top: -1px;
  }
  /* 
.logo-fr {
display: block;
width: 320px;
height: auto;
background: url(/src/images/logo-fr.svg);
background-size: 320px auto;
}

.logo-en {
  display: block;
  width: 320px;
  height: auto;
  background: url(/src/images/chrc-ccdp-logo.svg);
  background-size: 320px auto;

}
 */
